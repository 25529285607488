<template>
  <div>
    <el-page-header @back="dynamic" content="角色配置" style="margin-bottom: 15px;">
    </el-page-header>
    <template>
      <el-button type="text">
        <el-button size="mini" type="primary" @click="dialog = true">新增角色</el-button>
      </el-button>
      <el-button type="text">
        <el-button size="mini" type="primary" @click="dialog = true">新增权限</el-button>
      </el-button>
      <el-drawer
          title="角色配置"
          :visible.sync="dialog"
          direction="rtl"
          custom-class="demo-drawer"
          ref="drawer"
          size="40%"
      >
        <div class="demo-drawer__content" style="margin-left: 10px; margin-right: 10px">
          <el-descriptions title="用户信息">
            <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
            <el-descriptions-item label="关联员工">18100000000</el-descriptions-item>
          </el-descriptions>
          <el-descriptions>
            <el-descriptions-item label="角色名称">kooriookami</el-descriptions-item>
          </el-descriptions>
          <el-form :model="form">

          </el-form>
          <div class="demo-drawer__footer">
            <el-button @click="cancelForm">取 消</el-button>
            <el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
          </div>
        </div>
      </el-drawer>
    </template>
    <el-table
        :data="reloData"
        style="width: 100%; margin: auto"
        :cell-style="{padding:0}"
        height="456">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          prop="rname"
          label="姓名"
          width="100">
      </el-table-column>
      <el-table-column
          prop="rsn"
          label="性别"
          width="100">
      </el-table-column>
      <el-table-column
          prop="reloPermission"
          label="权限">
        <template slot-scope="reloPer">
          <template v-for="(per,key) in reloPer.row.reloPermission.permission">
            <el-tag v-bind:key="key" size="small" style="margin-left: 5px">{{ per.pname }}</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button
              @click.native.prevent="deleteRow(scope.$index, tableData)"
              style="padding: 2px; margin: 5px">
            <i class="el-icon-delete"></i>
          </el-button>
          <el-button

              style="padding: 2px; margin: 5px 0px">
            <i class="el-icon-edit"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "relo",
  data() {
    return {
      dialog: false,
      form: {
        empName: '',
        empSexy: '1',
        empNum: '',
        depId: '',
        empPost: '',
        empDate: ''
      },
      formLabelWidth: '80px',
      reloData: [

      ]
    }
  },
  mounted() {
    this.getReloList()
  },
  methods: {
    dynamic() {
      this.$router.push("dynamic")
    },
    getReloList() {
      this.$http({
        method: 'get',
        url: '/api/user/getReloList'
      }).then((res) =>{
        this.reloData = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>